.mb-footer-container {
  color: #ffde97;
  width: 100%;
  background-color: #ff9b42;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width <= 991px) {
  .mb-footer-container {
    height: 15rem;
    margin-top: 1rem;
    position: relative;
  }

  .mb-footer-link-divider {
    display: none;
  }
}

.mb-footer-link-container {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.mb-footer-link-container a {
  color: #ffde97;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
}

@media (width <= 991px) {
  .mb-footer-link-container {
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.mb-footer-wrapper {
  width: 73rem;
  height: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.mb-footer-copyright-container {
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  display: flex;
}

/*# sourceMappingURL=index.cfd2209d.css.map */
