@import 'src/assets/scss/partials/variables';
@import "src/styles/_mixins";

.mb-footer-container {
  color: #ffde97;
  background-color: #ff9b42;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include md {
    position: relative;
    height: 15rem;
    margin-top: 1rem;
  }
}

.mb-footer-link-divider {
  @include md {
    display: none;
  }
}

.mb-footer-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    margin-right: 1rem;
    margin-left: 1rem;
    color: #ffde97;
    text-decoration: none;
  }

  @include md {
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.mb-footer-wrapper {
  width: 73rem;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

}

.mb-footer-copyright-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
}

